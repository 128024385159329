// src/components/Auth/PasswordReset.js
import { sendPasswordResetEmail } from "firebase/auth";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../../firebase";
import AuthError from "../../components/AuthError";
import AuthPageLayout from "../../layouts/AuthPageLayout";

export default function PasswordReset() {
  const emailRef = useRef();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setMessage("");
      await sendPasswordResetEmail(auth, emailRef.current.value);
      setMessage("Check your inbox for further instructions");
    } catch (error) {
      setError("Failed to reset password: " + error.message);
      console.error("Error resetting password:", error);
    }
  }

  return (
    <AuthPageLayout header="Password Reset">
      <AuthError error={error} />

      {/* Success Message */}
      {message && (
        <div
          className="flex items-center p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
          role="alert"
        >
          <svg
            className="flex-shrink-0 inline w-4 h-4 mr-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Success</span>
          <div>
            <span className="font-medium">Success:</span> {message}
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {/* Email Input */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            ref={emailRef}
            required
            className="w-full p-3 border border-gray-300 rounded"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition-colors"
        >
          Reset Password
        </button>
      </form>

      {/* Link to Login */}
      <div className="mt-6 text-center">
        <Link to="/login" className="text-blue-500 hover:underline">
          Login
        </Link>
      </div>
    </AuthPageLayout>
  );
}
