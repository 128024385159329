// src/common/components/NavBar.js
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Logo from "./Logo";

const VISIBLE_ALL_USERS = 0;
const VISIBLE_LOGGED_IN_ONLY = 1;
const VISIBLE_LOGGED_OUT_ONLY = -1;
// New MobileMenuButton component
function MobileMenuButton({ isMobileMenuOpen, setIsMobileMenuOpen }) {
  return (
    <div className="flex md:hidden">
      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="text-white px-3 py-2 h-10 flex items-center justify-center"
        aria-label="Toggle Menu"
      >
        {isMobileMenuOpen ? (
          <XIcon className="h-6 w-6 text-white" />
        ) : (
          <MenuIcon className="h-6 w-6 text-white" />
        )}
      </button>
    </div>
  );
}

function NavLink({ caption, link, target, closeMenu }) {
  const navigate = useNavigate();
  let onClick = null;
  if (target === "") {
    onClick = (event) => {
      event.preventDefault();
      closeMenu();
      navigate(link);
    };
  }
  return (
    <a
      key={caption}
      href={link}
      target={target}
      onClick={onClick}
      rel="noopener noreferrer"
      className="text-white px-3 py-2 mr-4 h-10 flex items-center justify-center"
    >
      {caption}
    </a>
  );
}

export default function NavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { currentUser } = useAuth(); // Get the logged-in user and subscription status

  // Define links at the top
  const links = [
    {
      caption: "Support",
      link: "https://discord.gg/KjNpZevaTp",
      target: "_blank",
      visible: VISIBLE_ALL_USERS,
    },
    {
      caption: "Logout",
      link: "/logout",
      target: "",
      visible: VISIBLE_LOGGED_IN_ONLY,
    },
    {
      caption: "Login",
      link: "/login",
      target: "",
      visible: VISIBLE_LOGGED_OUT_ONLY,
    },
  ];

  const linksToShow = links.filter(
    (link) =>
      link.visible === VISIBLE_ALL_USERS ||
      (currentUser && link.visible === VISIBLE_LOGGED_IN_ONLY) ||
      (!currentUser && link.visible === VISIBLE_LOGGED_OUT_ONLY)
  );

  let closeMenu = () => setIsMobileMenuOpen(false);
  const navigate = useNavigate();

  return (
    <nav id="dashboard-nav" className="bg-red-600 shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <a
            className="flex-shrink-0"
            style={{ marginLeft: "48px" }}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              closeMenu();
              navigate("/");
            }}
          >
            <Logo className="h-8" />
          </a>

          {/* Desktop Menu - Aligned Right */}
          <div className="hidden md:flex items-center ml-auto">
            {/* Render Links */}
            {linksToShow.map(({ caption, link, target }) => (
              <NavLink
                key={caption}
                caption={caption}
                link={link}
                target={target}
                closeMenu={closeMenu}
              />
            ))}
          </div>

          {/* Mobile Menu Button */}
          <MobileMenuButton
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-[#FF3A20]">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/* Render Mobile Links */}
            {linksToShow.map(({ caption, link, target }) => (
              <NavLink
                key={caption}
                caption={caption}
                link={link}
                target={target}
                closeMenu={closeMenu}
              />
            ))}
          </div>
        </div>
      )}
      {isMobileMenuOpen && (
        <div
          id="nav-catch"
          className="h-full w-full fixed"
          onClick={closeMenu}
        />
      )}
    </nav>
  );
}
