// src/common/layouts/AuthPageLayout.js

import AppPageLayout from "./AppPageLayout";

export default function AuthPageLayout({ children, header }) {
  return (
    <AppPageLayout>
      {header && (
        <h2 className="text-2xl font-semibold mb-6 text-center">{header}</h2>
      )}
      {children}
    </AppPageLayout>
  );
}
