import { createBrowserRouter } from "react-router-dom";
import LoginPage from "./common/pages/LoginPage";
import LogoutPage from "./common/pages/LogoutPage";
import PasswordResetPage from "./common/pages/PasswordResetPage";
import SignupPage from "./common/pages/SignupPage";
import Affiliate from "./pages/Affiliate";
import Home from "./pages/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/password-reset",
    element: <PasswordResetPage />,
  },
  {
    path: "/affiliate",
    element: <Affiliate />,
  },
  {
    path: "/affiliate/:domainName",
    element: <Affiliate />,
  },
  {
    path: "*",
    element: <p>404 Error - Nothing here...</p>,
  },
]);

export default router;
